import React, { useState } from 'react'

import clsx from 'clsx'

import { Collapse } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'

import SidebarUserbox from '../SidebarUserbox'

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone'
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone'
import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone'
import CameraAltTwoToneIcon from '@material-ui/icons/CameraAltTwoTone'
import CollectionsTwoToneIcon from '@material-ui/icons/CollectionsTwoTone'
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone'
import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone'
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone'
import MoveToInboxTwoToneIcon from '@material-ui/icons/MoveToInboxTwoTone'
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import DepartureBoardTwoToneIcon from '@material-ui/icons/DepartureBoardTwoTone'
import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone'
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone'
import DevicesOtherTwoToneIcon from '@material-ui/icons/DevicesOtherTwoTone'
import LinkTwoToneIcon from '@material-ui/icons/LinkTwoTone'

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SecurityTwoToneIcon from '@material-ui/icons/SecurityTwoTone'
import CameraTwoToneIcon from '@material-ui/icons/CameraTwoTone'

import HomeIcon from '@material-ui/icons/Home'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import StorageIcon from '@material-ui/icons/Storage'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import StoreIcon from '@material-ui/icons/Store'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SettingsIcon from '@material-ui/icons/Settings'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SidebarMenu = props => {
  const { setSidebarToggleMobile, sidebarUserbox } = props

  const toggleSidebarMobile = () => setSidebarToggleMobile(false)

  const [patientsOpen, setPatientsOpen] = useState(false)
  const togglePatients = event => {
    setPatientsOpen(!patientsOpen)
    event.preventDefault()
  }

  const [purchaseOpen, setPurchaseOpen] = useState(false)
  const togglePurchase = event => {
    setPurchaseOpen(!purchaseOpen)
    event.preventDefault()
  }

  const [accountsOpen, setAccountsOpen] = useState(false)
  const toggleAccounts = event => {
    setAccountsOpen(!accountsOpen)
    event.preventDefault()
  }

  const [reportsOpen, setReportsOpen] = useState(false)
  const toggleReports = event => {
    setReportsOpen(!reportsOpen)
    event.preventDefault()
  }

  const [dataHubOpen, setDataHubOpen] = useState(false)
  const toggleDataHub = event => {
    setDataHubOpen(!dataHubOpen)
    event.preventDefault()
  }

  const [settingsOpen, setSettingsOpen] = useState(false)
  const toggleSettings = event => {
    setSettingsOpen(!settingsOpen)
    event.preventDefault()
  }

  const [crmOpen, setCrmOpen] = useState(false)
  const toggleCrm = event => {
    setCrmOpen(!crmOpen)
    event.preventDefault()
  }

  const [levelsOpen, setLevelsOpen] = useState(false)
  const toggleLevels = event => {
    setLevelsOpen(!levelsOpen)
    event.preventDefault()
  }

  const [widgetsOpen, setWidgetsOpen] = useState(false)
  const toggleWidgets = event => {
    setWidgetsOpen(!widgetsOpen)
    event.preventDefault()
  }

  const [chartsOpen, setChartsOpen] = useState(false)
  const toggleCharts = event => {
    setChartsOpen(!chartsOpen)
    event.preventDefault()
  }

  const [uiKitComponentsOpen, setUiKitComponents] = useState(false)
  const toggleUiKitComponents = event => {
    setUiKitComponents(!uiKitComponentsOpen)
    event.preventDefault()
  }

  const [formsComponentsOpen, setFormsComponents] = useState(false)
  const toggleFormsComponents = event => {
    setFormsComponents(!formsComponentsOpen)
    event.preventDefault()
  }

  const [collapsedLayoutOpen, setCollapsedLayoutOpen] = useState(false)
  const toggleCollapsedLayout = event => {
    setCollapsedLayoutOpen(!collapsedLayoutOpen)
    event.preventDefault()
  }

  const [pagesLoginOpen, setPagesLoginOpen] = useState(false)
  const togglePagesLogin = event => {
    setPagesLoginOpen(!pagesLoginOpen)
    event.preventDefault()
  }

  const [pagesRegisterOpen, setPagesRegisterOpen] = useState(false)
  const togglePagesRegister = event => {
    setPagesRegisterOpen(!pagesRegisterOpen)
    event.preventDefault()
  }

  const [pagesRecoverOpen, setPagesRecoverOpen] = useState(false)
  const togglePagesRecover = event => {
    setPagesRecoverOpen(!pagesRecoverOpen)
    event.preventDefault()
  }

  return (
    <PerfectScrollbar>
      {sidebarUserbox && <SidebarUserbox />}
      <div className='sidebar-navigation'>
        <div className='sidebar-header'>
          <span>Navigation menu</span>
        </div>
        <ul>
          <li>
            <NavLink
              activeClassName='active'
              onClick={toggleSidebarMobile}
              className='nav-link-simple'
              to='/all-patients'>
              <span className='sidebar-icon'>
                <FontAwesomeIcon icon={['fas', 'procedures']} />
              </span>
              All Patients
              {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRightTwoToneIcon />
              </span> */}
            </NavLink>
          </li>
          {[
            {
              name: 'New Patient',
              url: '/patients/NewPatient',
              icon: 'id-badge'
            },
            {
              name: 'In Progress',
              url: '/patients/InProgress',
              icon: 'clipboard-list'
            },
            {
              name: 'Need Attention',
              url: '/patients/NeedAttention',
              icon: 'bell'
            },
            {
              name: 'Verified',
              url: '/patients/Verified',
              icon: 'check-circle'
            },
            { name: 'Printed', url: '/patients/Printed', icon: 'print' },
            { name: 'Archived', url: '/patients/Archived', icon: 'archive' }
          ].map(item => (
            <li>
              <NavLink
                activeClassName='active'
                onClick={toggleSidebarMobile}
                className='nav-link-simple'
                to={item.url}>
                <span className='sidebar-icon'>
                  <FontAwesomeIcon icon={['fas', item.icon]} />
                </span>
                {item.name}
                {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                      <ChevronRightTwoToneIcon />
                    </span> */}
              </NavLink>
            </li>
          ))}

          {/* <li>
            <a
              href="#/"
              onClick={togglePatients}
              className={clsx({ active: patientsOpen })}>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={['fas', 'tasks']} />
              </span>
              <span className="sidebar-item-label">Patients Management</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={patientsOpen}>
              <ul>
                {[
                  { name: 'New Patient', url: '/patients/NewPatient' },
                  { name: 'In Progress', url: '/patients/InProgress' },
                  { name: 'Need Attention', url: '/patients/NeedAttention' },
                  { name: 'Verified', url: '/patients/Verified' },
                  { name: 'Archived', url: '/patients/Archived' }
                ].map((item) => (
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to={item.url}>
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Collapse>
          </li> */}

          {(localStorage.getItem('systemRole') == 'DentalOffice' ||
            JSON.parse(localStorage.getItem('dentalOffice')).managerRole) && (
            <>
              <li>
                <NavLink
                  activeClassName='active'
                  onClick={toggleSidebarMobile}
                  className='nav-link-simple'
                  to='/reports'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'clipboard']} />
                  </span>
                  Reports
                  {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRightTwoToneIcon />
              </span> */}
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName='active'
                  onClick={toggleSidebarMobile}
                  className='nav-link-simple'
                  to='/locations'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                  </span>
                  Location
                  {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRightTwoToneIcon />
              </span> */}
                </NavLink>
              </li>

              <li>
                <NavLink
                  activeClassName='active'
                  onClick={toggleSidebarMobile}
                  className='nav-link-simple'
                  to='/users'>
                  <span className='sidebar-icon'>
                    <FontAwesomeIcon icon={['fas', 'users']} />
                  </span>
                  Users
                  {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRightTwoToneIcon />
              </span> */}
                </NavLink>
              </li>
            </>
          )}
          {/* <li>
            <a
              href="#/"
              onClick={toggleCrm}
              className={clsx({ active: crmOpen })}>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={['fas', 'tasks']} />
              </span>
              <span className="sidebar-item-label">CRM</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={crmOpen}>
              <ul>
                <li>
                  <NavLink onClick={toggleSidebarMobile} to="/leads">
                    Leads
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={toggleSidebarMobile} to="/opportunity">
                    Opportunities
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              to="/projects">
              <span className="sidebar-icon">
                <AssignmentIcon />
              </span>
              Projects
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRightTwoToneIcon />
              </span>
            </NavLink>
          </li> */}
          {/*         
          <li>
            <a
              href="#/"
              onClick={toggleSettings}
              className={clsx({ active: settingsOpen })}>
              <span className="sidebar-icon">
                <SettingsIcon />
              </span>
              <span className="sidebar-item-label">Settings</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={settingsOpen}>
              <ul>
                {[
                  { name: 'Users', url: '/users' },
                  // { name: 'Business', url: '/business' },
                  { name: 'Roles', url: '/roles' },
                  { name: 'Two Factor Auth', url: '/two-factor-auth' },
                  // { name: 'Export Data', url: '/export-data' },
                  // { name: 'Purchase Product', url: '/purchase-product' },
                  // { name: 'Categories', url: '/categories' },
                  // { name: 'Custom Fields', url: '/custom-fields' },
                  // { name: 'Currency', url: '/currency' },
                  {
                    name: (
                      <>
                        Firewall
                        <div
                          className="badge badge-danger h-auto py-0 ml-2"
                          style={{ width: '6em' }}>
                          Disabled
                        </div>
                      </>
                    ),
                    url: '/firewall'
                  },
                  { name: 'Settings', url: '/settings' }
                ].map((item) => (
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to={item.url}>
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Collapse>
          </li> */}
          <li>
            <NavLink
              activeClassName='active'
              onClick={toggleSidebarMobile}
              className='nav-link-simple'
              to='/logout'>
              <span className='sidebar-icon'>
                <FontAwesomeIcon icon={['fas', 'power-off']} />
              </span>
              Logout
              {/* <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRightTwoToneIcon />
              </span> */}
            </NavLink>
          </li>
        </ul>
      </div>
    </PerfectScrollbar>
  )
}

const mapStateToProps = state => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,

  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
