import React from 'react';

import PageError4041 from '../../example-components/PageError404/PageError4041';
export default function PageError404() {
  return (
    <>
      <PageError4041 />
    </>
  );
}
