import React from 'react';

import PageError5001 from '../../example-components/PageError500/PageError5001';
export default function PageError500() {
  return (
    <>
      <PageError5001 />
    </>
  );
}
